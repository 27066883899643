<template>
  <div id="pageContent">
    <div style="padding: 10px">
      <el-button type="primary" size="mini" @click="addDisEvent"
        >添加</el-button
      >
    </div>
    <div style="margin-left: 10px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>

        <el-table-column prop="id" label="Id" width="50" align="center">
        </el-table-column>

        <el-table-column prop="sort_index" label="活动排序" width="50" align="center">
        </el-table-column>

        <el-table-column prop="name" label="活动名称" align="left">
        </el-table-column>

        <el-table-column label="折扣度" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.dis }}</template>
        </el-table-column>

        <el-table-column label="开始时间" width="150" align="center">
          <template slot-scope="scope">
            {{
              scope.row.be_time
                ? new Date(scope.row.be_time * 1000).toLocaleString(
                    "zh-Hans-CN"
                  )
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="结束时间" width="150" align="center">
          <template slot-scope="scope">
            {{
              scope.row.end_time
                ? new Date(scope.row.end_time * 1000).toLocaleString(
                    "zh-Hans-CN"
                  )
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="220">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="danger" size="mini" @click="editInfo(scope.row)"
                >编辑</el-button
              >
              <el-button type="infor" size="mini" @click="toInfo(scope.row)"
                >查看</el-button
              >
              <el-button type="warning" size="mini" @click="delDis(scope.row)"
                >删除</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div ref="footer" class="footer">
      <el-pagination
        :key="reloadPaginate"
        :current-page="pagination.page"
        :page-size="pagination.pageSize"
        :page-sizes="[10, 20, 50, 100, 200]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
      />
    </div>

    <!-- 抽屉效果 -->
    <el-drawer
      size="50%"
      title="活动管理"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div style="border: 1px solid #ccc;border-right: none;border-left: none;padding: 5px;width: 95%;margin: 0 auto;">
        <el-form ref="form" :model="form" label-width="130px">
          <el-form-item label="活动名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="活动折扣" v-if="showDis==true">
            <el-input-number
              v-model="form.dis"
              label="描述文字"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="活动时间">
            <el-col :span="11">
              <el-date-picker
                v-model="form.times"
                type="daterange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>

          <el-form-item label="活动排序">
            <el-input type="number" v-model="form.sort_index" @input="value=form.sort_index.replace(/[^d]/g,'')"></el-input>
          </el-form-item>

          <el-form-item label="折扣方式">
            <el-radio v-model="price_of" label="0">配送价</el-radio>
            <el-radio v-model="price_of" label="1">零售价</el-radio>
            <el-radio v-model="price_of" label="2">固定价,自定义折扣价</el-radio>
            <el-radio v-model="price_of" label="3">仅固定价,无折扣价</el-radio>
          </el-form-item>

          <el-form-item label="是否显示new提示">
            <el-radio v-model="is_tip_update" label="0">不显示</el-radio>
            <el-radio v-model="is_tip_update" label="1">显示</el-radio>
          </el-form-item>

          <el-form-item label="提示new时间">
            <el-col :span="11">
              <el-date-picker
                v-model="form.tip_times"
                type="daterange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="drawer = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import common from "@/common/mixins/common.js";
export default {
  mixins: [common],
  data() {
    return {
      disType: 0,
      tableData: [],
      multipleSelection: [],
      reloadPaginate: 0,
      pagination: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      drawer: false,
      direction: "rtl",
      form: {
        name: "",
        dis: 8,
        times: [],
        price_of: 0, //提交到数据库的参数
        sort_index:0,//排序
        is_tip_update:0,
        tip_times:[],
      },
      price_of: "0", //用来显示默认值  价格折扣方式
      is_tip_update:"0",
      showDis:true,//是否显示折扣

      formId: 0,
    };
  },
  watch:{
    'price_of'(val){
      if (parseInt(val) === 3 || parseInt(val)=== 2) {
        this.showDis = false
        this.form.dis = 0
      }else{
        this.showDis = true
      }
    }
  },
  created() {
    this.getDisData();
  },
  methods: {
    // 分页跳转
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getDisData();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getDisData();
    },
    //查看活动详情的商品列表
    toInfo(row) {
      this.$router.push({
        path: "discountGoodsList",
        query: {
          id: row.id,
        },
      });
    },
    delDis(row) {
      this.$confirm("你确定要删除这个活动吗？")
        .then((_) => {
          this.$axios.delete("/a1/dis/" + row.id).then((res) => {
            this.$message.success("删除成功");
            this.getDisData();
          });
        })
        .catch((_) => {
          this.$message.info("取消删除");
        });
    },
    //提交
    onSubmit() {
      console.log(this.form)
      this.form.times = [this.form.times[0] / 1000, this.form.times[1] / 1000];
      this.form.tip_times =this.form.tip_times.length>0? [this.form.tip_times[0] / 1000,this.form.tip_times[1] / 1000]:[]
      this.form.price_of = parseInt(this.price_of);
      this.form.sort_index = parseInt(this.form.sort_index)
      this.form.is_tip_update = parseInt(this.is_tip_update)
      if (this.formId > 0) {
        this.$axios.put("/a1/dis/" + this.formId, this.form).then((res) => {
          this.$message.success("修改成功");
          this.getDisData();
          this.drawer = false;
        }).catch((err)=>{
          this.drawer = false;
          this.$message.error(err.response.data.message);
        });
      } else if (this.formId == 0) {
        this.$axios.post("/a1/dis/", this.form).then((res) => {
          this.$message.success("添加成功");
          this.getDisData();
          this.drawer = false;
        }).catch((err)=>{
          this.drawer = false;
          this.$message.error(err.response.data.message);
        });
      }
    },
    //修改详情
    editInfo(row) {
      this.form.name = row.name;
      this.form.dis = row.dis;
      this.form.price_of = row.dis_type;
      this.price_of = row.dis_type.toString();
      this.formId = row.id;
      let beTime = row.be_time * 1000;
      let endTime = row.end_time * 1000;
      this.form.times = [beTime, endTime];
      this.drawer = true;
      this.form.sort_index = row.sort_index
      this.form.is_tip_update = row.is_tip_update
      this.is_tip_update = row.is_tip_update.toString()
      let tip_update_start_time = row.tip_update_start_time?row.tip_update_start_time * 1000:""
      let tip_update_end_time = row.tip_update_end_time?row.tip_update_end_time * 1000:""
      this.form.tip_times = tip_update_start_time && tip_update_end_time?[tip_update_start_time,tip_update_end_time]:[]
    },
    //添加活动触发
    addDisEvent() {
      this.form = {
        name: "",
        dis: 9,
        times: [],
        sort_index:0,
        tip_times:[]
      };
      this.formId = 0;
      this.drawer = true;
    },
    //抽屉关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //获取到活动数据
    getDisData() {
      this.tableData = [];
      this.pagination.total = 0;
      this.$axios
        .get("/a1/dis", {
          params: {
            pageSize: this.pagination.pageSize,
            page: this.pagination.page,
          },
        })
        .then((res) => {
          const { data } = res.data;
          this.pagination.total = data.total;
          this.tableData = data.data;
          this.reloadPaginate++;
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style>
.footer {
  margin: 20px 0 0 10px;
}
</style>
